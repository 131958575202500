import React, { useEffect, useState } from 'react';
import BasicPage from './page-types/BasicPage';
import SkillCard from '../skill/SkillCard';
import { useParams, useNavigate } from 'react-router-dom';
import LeftArrow from '../../images/left-arrow-transparent.svg';
import RightArrow from '../../images/right-arrow-transparent.svg';
import Dot from '../../images/selector-dot.svg';
import StretchedDot from '../../images/stretched-dot.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setSkill, loadStateFromDatabase } from '../../store/skillSlice';
import Database from '../../utils/Database.js';
import SpecifiedActivityTracker from '../activity/SpecifiedActivityTracker.js';
import WeekTracker from '../WeekTracker.js';

function SkillPage(props) {
    const { skillId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentSkill = useSelector(state => state.skill);
    const user = useSelector(state => state.user.user);

    const skillIndex = props.skills.findIndex((skill) => skill.id == skillId);
    const skill = props.skills.filter((skill) => skill.id == skillId)[0];

    /**
     * Setting which skill is currently being tracked.
     */
    useEffect(() => {
        if (!skill && user)
        {
            getSkill();
        }
    }, []);

    /**
     * This will get the skill by ID.
     *
     * @returns {void}
     */
    async function getSkill()
    {
        const {data, error} = await Database.getSkillById(user, skillId);
        if (error) {
            console.error(error);
            return;
        }
        const responseData = data[0];
        dispatch(loadStateFromDatabase(responseData));
    }

    /**
     * This will set the next skill.
     *
     * @param {string} direction
     */
    function nextSkill(direction) {
        const skills = props.skills;

        let nextSkillIndex = 0;
        if (direction === 'right') {
            nextSkillIndex = skillIndex + 1;
        } else {
            nextSkillIndex = skillIndex - 1;
        }

        /**
         * If the next skill index is out of bounds,
         * then reset it to the other end of the array.
         */
        if (nextSkillIndex < 0) {
            nextSkillIndex = skills.length - 1;
        } else if (nextSkillIndex >= skills.length) {
            nextSkillIndex = 0;
        }

        const nextSkill = skills[nextSkillIndex];

        dispatch(setSkill(nextSkill));

        /**
         * Update skill id in URL
         */
        navigate(`/skill/${nextSkill.id}`);
    }

    const selectorDots = props.skills.map((skill, index) => {
        const src = index === skillIndex ? StretchedDot : Dot;
        return (
            <img key={index} src={src} alt='dot' />
        );
    });

    return (
        <BasicPage
            prevLink="/"
            prevName="Dashboard"
            className="skill-page"
        >
            <WeekTracker skillId={skillId} />
            <SkillCard {...props} large={true} skillId={skillId} skill={skill}/>
            <div className="flex justify-between switch-skill-container">
                <button className="switch-skill-btn" onClick={() => nextSkill('left')}>
                    <img src={LeftArrow} alt="left arrow" />
                </button>
                <div className="dot-container">
                    {selectorDots}
                </div>
                <button className="switch-skill-btn" onClick={() => nextSkill('right')}>
                    <img src={RightArrow} alt="right arrow" />
                </button>
            </div>
            <SpecifiedActivityTracker skillId={skillId} />
        </BasicPage>
    );
}

export default SkillPage;