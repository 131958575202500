import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { H2, H5 } from './UI/headings';
import Circle from '../images/circle.svg';
import CheckCircle from '../images/check-circle-new.svg';
import TimerIcon from '../images/timer-icon.svg';
import Icon from './UI/Icon';
import Database from '../utils/Database';
import DateHelper from '../utils/DateHelper';
import { cloneObject } from '../utils/utils';

const weekDefault = {
    'Monday': 0,
    'Tuesday': 0,
    'Wednesday': 0,
    'Thursday': 0,
    'Friday': 0,
    'Saturday': 0,
    'Sunday': 0
};

/**
 * WeekTracker
 */
const WeekTracker = React.memo(function (props) {
    const [week, setWeek] = useState(weekDefault);
    const user = useSelector(state => state.user.user);
    const activities = useSelector(state => state.activities.recentActivities);
    const [totalTime, setTotalTime] = useState(0);

    /**
     * This will calculate the total time for the
     * activities of the day.
     *
     * @param {string} start
     * @param {string} end
     */
    const calculateTotalTime = useCallback((start, end) => {
        start = start.split('T').pop();
        start = start.substring(0, 5);
        end = end.split('T').pop();
        end = end.substring(0, 5);

        const startHour = start.split(':')[0];
        const startMinute = start.split(':')[1].substring(0, 2);
        const endHour = end.split(':')[0];
        const endMinute = end.split(':')[1].substring(0, 2);

        let hours = endHour - startHour;
        let minutes = endMinute - startMinute;
        if (hours === 0 && minutes === 0) {
            return 0;
        }

        if (minutes < 0) {
            hours--;
            minutes = 60 - Math.abs(minutes);
        }

        return hours * 60 + minutes;
    }, []);

    /**
     * This will set the week object to the
     * days that were tracked.
     *
     * @param {array} data
     */
    const handleSettingWeek = useCallback((data) => {
        let tempWeek = cloneObject(weekDefault);

        let total = 0;
        for (let i = 0; i < data.length; i++) {
            total += calculateTotalTime(data[i].start_time, data[i].end_time);
            const day = DateHelper.getDay(data[i].date);
            tempWeek[day] = 1;
        }

        setTotalTime(total);

        setWeek((prev) => ({...prev, ...tempWeek}));
    }, [calculateTotalTime]);

    /**
     * This will get the days tracked
     * Monday - Sunday.
     *
     * @returns {void}
     */
    const getWeek = useCallback(async () => {
        if (!user) {
            return;
        }

        let result;
        if (props.skillId) {
            result = await Database.getWeeklyActivitiesForSkill(props.skillId);
        } else {
            result = await Database.getDaysTrackedThisWeek(user);
        }

        if (result.error) {
            console.error(result.error);
            return;
        }

        handleSettingWeek(result.data);
    }, [user, handleSettingWeek, props.skillId]);

    /**
     * Get days tracked,
     * each time the user changes.
     */
    useEffect(() => {
        getWeek();
    }, [activities, user, getWeek]);

    /**
     * This will abbreviate the day name.
     */
    function abbreviateDay(day) {
        return day.slice(0, 2);
    }

    const getTimeString = () => {
        const hours = Math.floor(totalTime / 60);
        const minutes = totalTime % 60;
        return `${hours}h ${minutes}m`;
    };

    const weekList = useMemo(() => Object.entries(week).map(([day, time], index) => {
        day = abbreviateDay(day);
        const icon = time > 0 ? CheckCircle : Circle;
        const alt = time > 0 ? 'Check circle' : 'Circle';
        const color = time > 0 ? 'color-accent' : '';

        const reactsSpecialKeyItNeeds = day + time + index;
        return (
            <div className="day flex flex-column align-center" key={reactsSpecialKeyItNeeds}>
                <p className={"day-label padding-bottom-6 text-1  " + color}>{day}</p>
                <Icon src={icon} alt={alt} size="large" />
            </div>
        );
    }), [week]);

    let classes = "week-tracker";
    if (props.skillId) {
        classes += " skill-specified";
    }
    return (
        <div className={classes}>
            <div className="heading-container">
                <H2>This Week</H2>
                <div className="week-total flex align-center">
                    <Icon className="margin-right-6" src={TimerIcon} alt={"Clock icon"} size="small" />
                    <H5 className="week-total-time">{getTimeString()}</H5>
                </div>
            </div>
            <div className="week flex space-between card bg-ele margin-bottom-2">
                {weekList}
            </div>
        </div>
    );
});

export default WeekTracker;